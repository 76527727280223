/* @import url('https://fonts.googleapis.com/css2?family=MuseoModerno&family=Varela+Round&display=swap'); */

:root {
  --primary: #f9f9f9;
  --secondary: #F3F3F7;
  --primary-color: #ae3764;
}

.uk-text-small, .uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, .uk-heading-2xlarge, .uk-heading-large, .uk-heading-medium,  .uk-heading-xlarge,  .uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
  /* font-family: 'Varela Round', sans-serif; */
  color: var(--primary-color);
}


.uk-background-primary {
  background-color: var(--primary);
}


@media (min-width: 960px){
  .tm-sidebar-left+.tm-main {
      padding-left: 240px;
  }
}

.uk-label {
  background: none;
  border: 1px solid black;
  color: black;
  border-radius: 6px;
}

.uk-table th {
  color: black;
  text-transform: lowercase;
  font-weight: 700;
  padding: 8px 1px;
}

html {
  color: black;
}

.uk-table td {
  padding: 8px 1px;
}

.uk-button {
  border-radius:6px;
  line-height: 30px;
  text-transform: capitalize;
}

.uk-button-small {
  line-height: 25px;
}