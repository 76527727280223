.tm-sidebar-left {
    position: fixed;
    top: 0px;
    bottom: 0;
    box-sizing: border-box;
    width: 240px !important;
    padding: 40px 40px 60px 40px;
    border-right: 1px #e5e5e5 solid;
    overflow: auto;
}

.uk-nav-primary>li>a {
    color: black;
    padding-left:10px;
    font-size: 18px;
}

.uk-nav-primary>li.uk-active>a {
    background-color: #f6f6f6;
    border-radius: 6px;
}